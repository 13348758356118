import React from 'react';
import { Box, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import RenderImage from '../../../Components/RenderImage/RenderImage';
import wallIcon from '../../../Resources/Icons/Instructions/wallIcon.svg';
import CustomMarkdownLink from '../../../Components/CustomMarkdownLink/CustomMarkdownLink';
import { preprocessMarkdown } from '../../../Utils/preprocessMarkdown/preprocessMarkdown';
import s from './ChatHistory.module.scss';

interface ContextItem {
  link: string;
  tags: string;
  text: string;
}

export enum ChatHistoryKey {
  User = 'user',
  Assistant = 'assistant',
  Context = 'context',
  ContextForDisplay = 'contextForDisplay'
}

export type ChatHistoryItem = {
  id: string;
  owner: ChatHistoryKey;
  message: string;
};

interface Props {
  chatHistory: ChatHistoryItem[];
}
const ChatHistory = ({ chatHistory }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      {chatHistory.map(i => {
        if (i.owner === ChatHistoryKey.User) {
          return (
            <Box key={i.id} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box
                sx={{
                  background: '#F3F3F3',
                  padding: '1rem',
                  borderRadius: '15px 15px 0 15px',
                  width: 'fit-content'
                }}
              >
                {i.message}
              </Box>
            </Box>
          );
        } else if (i.owner === ChatHistoryKey.Assistant) {
          return (
            <Box key={i.id} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Box
                sx={{
                  background: '#FFFFFF',
                  padding: '1rem',
                  borderRadius: '15px 15px 15px 0',
                  width: 'fit-content',
                  overflowX: 'auto'
                }}
              >
                <Markdown
                  components={{
                    a: ({ node, ...props }) => <CustomMarkdownLink {...props} />
                  }}
                >
                  {preprocessMarkdown(i.message)}
                </Markdown>
              </Box>
            </Box>
          );
        } else if (i.owner === ChatHistoryKey.ContextForDisplay) {
          // This contains links to Wall resources (areas, clusters, instructions)
          const contextArr: ContextItem[] = JSON.parse(i.message);
          const uniqueContextArr = contextArr.reduce((acc, item) => {
            if (!acc.some(obj => obj.link === item.link)) {
              acc.push(item);
            }
            return acc;
          }, []);
          return (
            <Box key={i.id} className={s.messageContext}>
              {uniqueContextArr.map(contextItem => {
                const url = new URL(contextItem.link.replace('/#', ''));
                const params = new URLSearchParams(url.search);

                let id = '';
                if (params.has('clusterId')) {
                  id = params.get('clusterId');
                } else if (params.has('areaId')) {
                  id = params.get('areaId');
                }

                return (
                  <Box
                    key={contextItem.link}
                    component="a"
                    href={`/${contextItem.link.replace(/^[^#]+/, '')}${
                      id ? '&search' : ''
                    }`}
                    sx={{
                      backgroundColor: '#FFFFFF',
                      borderRadius: '15px',
                      padding: '12px 12px 8px',
                      width: '99px',
                      textDecoration: 'none',
                      color: 'inherit'
                    }}
                  >
                    <Box
                      sx={{
                        '& img': {
                          width: '100%',
                          overflow: 'hidden',
                          objectFit: 'cover',
                          height: '76px'
                        }
                      }}
                    >
                      {id ? (
                        <RenderImage id={id} alt={id} />
                      ) : (
                        <img src={wallIcon} alt="The Wall Global icon" />
                      )}
                    </Box>
                    <Typography
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        textAlign: 'left'
                      }}
                    >
                      {contextItem.text}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default ChatHistory;
