import { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import {
  ChatHistoryItem,
  ChatHistoryKey
} from '../Containers/wallGuideAI/ChatHistory/ChatHistory';
import { MAXIMUM_LENGTH_HISTORY } from '../Containers/wallGuideAI/WallGuideAIChat/WallGuideAIChat';

const URL_AI_WS = window.config.URL_AI_WS;
const RECONNECT_INTERVAL = 5000;
const START_OF_SEQUENCE = '<sos>';
const END_OF_SEQUENCE = '<eos>';

interface ResponseObject {
  greeting?: string;
  chunk?: string;
  context?: string;
}

interface Props {
  setChatHistory: React.Dispatch<React.SetStateAction<ChatHistoryItem[]>>;
  setAssistantMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useAiWs = ({
  setChatHistory,
  setAssistantMessage,
  setIsLoading
}: Props) => {
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (!ws) {
      try {
        const socket = new WebSocket(URL_AI_WS);

        socket.onclose = event => {
          if (!event.wasClean) {
            setTimeout(() => {
              setWs(null);
            }, RECONNECT_INTERVAL);
          }
        };

        socket.onmessage = event => {
          const responseObject: ResponseObject = JSON.parse(event.data);

          if (responseObject?.greeting) {
            // This is the welcome line that is displayed at startup.
            setChatHistory(prev => {
              if (Array.isArray(prev) && prev.length > 0) {
                return prev;
              }
              const updatedData: ChatHistoryItem[] = [
                ...prev,
                {
                  id: uniqueId(),
                  owner: ChatHistoryKey.Assistant,
                  message: responseObject.greeting
                }
              ];
              return updatedData;
            });
            return;
          }

          if (responseObject?.context) {
            // This contains links to Wall resources (areas, clusters, instructions)
            setChatHistory(prev => {
              const updatedData: ChatHistoryItem[] = [
                ...prev,
                {
                  id: uniqueId(),
                  owner: ChatHistoryKey.Context,
                  message: responseObject.context
                }
              ];
              if (prev.length > MAXIMUM_LENGTH_HISTORY) {
                updatedData.shift();
              }
              return updatedData;
            });
          }

          if (!responseObject?.chunk) {
            return;
          }

          const chunk = responseObject.chunk;
          if (chunk === START_OF_SEQUENCE) {
            // Start of current AI response
            setAssistantMessage('');
            setIsLoading(false);
            return;
          } else if (chunk === END_OF_SEQUENCE) {
            // End of current AI response
            // The block with links needs to be moved to the end.
            setAssistantMessage(assistantMessage => {
              setChatHistory(prev => {
                const lastElement =
                  prev.length > 0 ? prev[prev.length - 1] : null;
                const lastElementIsContext =
                  lastElement && lastElement.owner === ChatHistoryKey.Context;
                const updatedData: ChatHistoryItem[] = lastElementIsContext
                  ? [
                      ...prev.slice(0, -1),
                      {
                        id: uniqueId(),
                        owner: ChatHistoryKey.Assistant,
                        message: assistantMessage
                      },
                      {
                        id: uniqueId(),
                        owner: ChatHistoryKey.ContextForDisplay,
                        message: lastElement.message
                      }
                    ]
                  : [
                      ...prev,
                      {
                        id: uniqueId(),
                        owner: ChatHistoryKey.Assistant,
                        message: assistantMessage
                      }
                    ];
                if (prev.length > MAXIMUM_LENGTH_HISTORY) {
                  updatedData.shift();
                }
                return updatedData;
              });
              return null;
            });
          }

          setAssistantMessage(prev => {
            if (prev === null) return prev;
            return prev + chunk;
          });
        };

        setWs(socket);
      } catch {}
    }

    return () => {
      if (ws) {
        ws.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws]);

  return ws;
};

export default useAiWs;
